import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Banner from '../components/Banner';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import BlueSection from '../components/homepage/BlueSection';
import LaunchYourBrand from '../components/homepage/LaunchYourBrand';
import ButtonLink from '../components/ButtonLink';

const Marketing = (props) => {
  const [wordpressData, setWordpressData] = useState();
  const [dataLoading, setLoading] = useState(false);

  const fetchMarketingData = async () => {
    setLoading(true);
    return fetch(
      'https://content.gildform.com/wp-json/acf/v3/marketing_page/844'
    )
      .then((data) => data.json())
      .then((data) => {
        setWordpressData(data);
        setLoading(false);
        return data;
      })
      .catch((err) => err);
  };

  const data = wordpressData ? wordpressData.acf : null;
  const banner = data ? data.marketing_sections[0] : '';
  const cards = data ? data.marketing_cards : [];
  const blue_section = data ? data.marketing_blue_section[0] : '';
  const try_gildform_section = data
    ? data.marketing_try_gildform_section[0]
    : '';
  const launch_your_brand_section = data
    ? data.marketing_launch_your_brand_section
    : '';

  useEffect(() => {
    fetchMarketingData();
  }, []);

  return !data ? (
    <div className='text-center' style={{ marginTop: '5rem' }}>
      <i className='fas fa-circle-notch fa-spin' />
    </div>
  ) : (
    <>
      {props.profile && !props.profile.answers ? <Banner /> : null}
      <Layout
        light_header={undefined}
        sit_on_top
        className='home'
        noAnswers={props.profile && !props.profile.answers ? true : false}
      >
        <SEO title='About' />
        <div className='marketing-container'>
          <div className='marketing-hero-container'>
            <div className='hero-text'>
              <span className='marketing-hero-title'>{banner.title}</span>
              <span
                className='marketing-hero-description'
                dangerouslySetInnerHTML={{
                  __html: banner.description,
                }}
              />
            </div>
            <div className='hero-image'>
              <img src={banner.image.url} />
            </div>
          </div>
          <BlueSection location='marketing' cards={cards} />
          {launch_your_brand_section &&
            launch_your_brand_section.map((content, index) => (
              <LaunchYourBrand
                key={index}
                content={content}
                location={'marketing'}
              />
            ))}
          <div className='blue-section'>
            <div className='blue-container'>
              <div className='blue-image'>
                <img src={blue_section && blue_section.image.url} />
              </div>
              <div className='blue-text'>
                <span className='text-title'>{blue_section.title}</span>
                <span
                  className='text-content'
                  dangerouslySetInnerHTML={{
                    __html: blue_section.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className='try-gildform-section'>
            <div className='try-gildform-container'>
              <div className='try-gildform-text'>
                <span className='title'>{try_gildform_section.title}</span>
                <span
                  className='content'
                  dangerouslySetInnerHTML={{
                    __html: try_gildform_section.description,
                  }}
                />
                <ButtonLink
                  {...(try_gildform_section && try_gildform_section.button[0])}
                  className={'start-design-button'}
                />
              </div>
              <div className='try-gildform-image'>
                <img src={try_gildform_section.image.url} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs.configs,
  profile: state.login.auth && state.login.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
