import React from 'react';
import { Button } from 'react-bootstrap';
import checkCircle from '../../images/check-circle.png';
import ButtonLink from '../ButtonLink';

const LaunchYourBrand = ({ content, location }) => {
  const homePage = location === 'homepage';
  return (
    <div
      className={
        homePage
          ? 'launch-your-brand-section homepage'
          : 'launch-your-brand-section'
      }
    >
      <div
        className={homePage ? 'brand-section homepage' : 'brand-section'}
        style={homePage ? { marginLeft: '1rem' } : null}
      >
        <div className={homePage ? 'brand-homepage-title' : `brand-title`}>
          {content.title}
        </div>
        <div
          className={homePage ? 'brand-content homepage' : 'brand-content'}
          dangerouslySetInnerHTML={{
            __html: content.description,
          }}
        />
        <div className='brand-btn'>
          {content && (
            <ButtonLink
              {...content.button[0]}
              className={homePage ? 'apply-now-btn' : 'start-design-button'}
            />
          )}
        </div>
      </div>
      <div className={homePage ? 'bigger-card homepage' : 'bigger-card'}>
        <div className={homePage ? 'card homepage' : 'card'}>
          <div className='jewelry-pic'>
            <img src={content && content.card[0].card_image.url} />
          </div>
          <div className='jew-content'>
            {content && (
              <div className='content'>
                {content.card[0].card_list_items.map((item, idx) => {
                  return (
                    <div className='itemCnt' key={idx}>
                      <div className='item-img'>
                        <img src={checkCircle} />
                      </div>
                      <div className=''>{item.item}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className='img-card'>
          <img src={homePage ? content.image.source_url : content.image.url} />
        </div>
      </div>
    </div>
  );
};

export default LaunchYourBrand;
