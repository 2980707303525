import React from 'react';

const BlueSection = ({ location, cards }) => {
  return (
    <div
      className='icons-container'
      style={
        location === 'home'
          ? { backgroundColor: '#1B3966', color: 'white' }
          : { backgroundColor: 'white', color: 'black' }
      }
    >
      {cards.map((card, index) => (
        <div key={index} className='icon-card'>
          <div className='icon-image'>
            {card && card.image && card.image.source_url ? (
              <img src={card.image.source_url} alt='card-image' />
            ) : null}
            {card && card.image && card.image.url ? (
              <img src={card.image.url} alt='card-image' />
            ) : null}
          </div>
          <div className='icon-title'>
            <span>{card.title}</span>
          </div>
          <div className='icon-text'>
            <span
              style={
                location === 'home' ? { color: 'white' } : { color: 'black' }
              }
              dangerouslySetInnerHTML={{ __html: card.description }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlueSection;
